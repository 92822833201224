<template>
  <card
    :content="content"
    :schema="getSchemaSend"
    :form-data="getDataSend"
    :editable="editable"
    @update-form="updateSend"
    @submitAction="addSend"
  />
</template>

<script>
/* eslint-disable no-console */
import Card from "../components/Card";
import confSend from "../conf/confSend";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Send",
  components: { Card },
  title() {
    return this.$t(this.$route.meta.title);
  },

  data() {
    return {
      content: confSend.content,
      disabled: true,
      editable: true
    };
  },
  created() {
    this.$store.dispatch("send/loadSend");
  },
  computed: {
    ...mapGetters("send", ["getDataSend", "getSchemaSend"])
  },
  methods: {
    ...mapActions("send", ["updateSend", "addSend", "loadSend"])
  }
};
</script>

<style scoped></style>
